import styled from "styled-components";

export const Footerr = styled.div`
display: flex;
flex-direction: column;
align-items: center;
background-color: black;
p{
    color: white;
    display: flex;
    justify-content: center;
}
.icon{
    font-size: 20px;
}
`
export const Logo = styled.img`
width: 100px;
margin-top: 20px;
`